import React from 'react'
import {graphql} from 'gatsby'
import Layout from "../components/Layout";
import SliceZone from '../components/SliceZone'

const DesignStyle = ({pageContext, data}) => {
    if (!data) return null
    if (!pageContext) return null
   const document = data.prismicDesignStyles

   const prismicNavigation = data.prismicNavigation

   return (
      <Layout
         document={document}
         prismicNavigation={prismicNavigation}
      >
         <SliceZone sliceZone={document.data.body} nodeAttributes={pageContext}/>
     </Layout>
   )
}


export const query = graphql`
    query StyleQuery($uid: String, $lang: String) {
        prismicDesignStyles(uid: { eq: $uid }, lang: {eq: $lang}) {
            _previewable
            id
            uid
            lang
            type
            url
            tags
            alternate_languages {
                id
                type
                lang
                uid
            }
            data {
                meta_title
                meta_description
                body {
                    ... on PrismicDesignStylesDataBodyHero {
                        id
                        slice_type
                        primary {
                            title {
                                html
                                richText
                                text
                            }
                            image {
                                alt
                                url
                                dimensions {
                                    width
                                    height
                                }
                            }
                        }
                    }
                    ... on PrismicDesignStylesDataBodyMasonry {
                        id
                        slice_type
                        primary {
                            heading {
                                richText
                            }
                            description {
                                richText
                            }
                            link_url {
                                url
                                target
                                type
                                link_type
                                uid
                                tags
                                lang
                            }
                            link_text
                        }
                        items {
                            image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                    }
                }
                
        }
        }
        prismicNavigation(lang: { eq: $lang }) {
            ...HeaderQuery
            ...FooterQuery
        }
    }
`

export default DesignStyle
